"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RescriptUserStore = void 0;
var rescript_user_getters_1 = require("./rescript.user.getters");
var rescript_user_mutations_1 = require("./rescript.user.mutations");
var rescript_user_actions_1 = require("./rescript.user.actions");
var state = {
    rescriptUsers: []
};
exports.RescriptUserStore = {
    state: state,
    getters: rescript_user_getters_1.rescriptUserGetters,
    mutations: rescript_user_mutations_1.rescriptUserMutations,
    actions: rescript_user_actions_1.RescriptUserActions
};

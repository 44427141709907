"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthStore = void 0;
var auth_getters_1 = require("./auth.getters");
var auth_mutations_1 = require("./auth.mutations");
var auth_actions_1 = require("./auth.actions");
var state = {
    token: window.localStorage.getItem('jwt') || null,
};
exports.AuthStore = {
    state: state,
    getters: auth_getters_1.authGetters,
    mutations: auth_mutations_1.authMutations,
    actions: auth_actions_1.authActions
};

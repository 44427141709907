"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonalUserStore = void 0;
var personal_user_getters_1 = require("./personal.user.getters");
var personal_user_mutations_1 = require("./personal.user.mutations");
var personal_user_actions_1 = require("./personal.user.actions");
var state = {
    personalUsers: []
};
exports.PersonalUserStore = {
    state: state,
    getters: personal_user_getters_1.personalUserGetters,
    mutations: personal_user_mutations_1.personalUserMutations,
    actions: personal_user_actions_1.personalUserActions
};

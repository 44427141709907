"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.authMutations = void 0;
var auth_mutation_types_1 = require("./types/auth.mutation.types");
exports.authMutations = (_a = {},
    _a[auth_mutation_types_1.AuthMutationTypes.SET_TOKEN] = function (state, payload) {
        state.token = payload;
    },
    _a);

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.editUser = exports.createPersonalUser = exports.showPersonalUsers = void 0;
var show_personal_users_component_vue_1 = require("../components/show/show.personal.users.component.vue");
var create_personal_user_component_vue_1 = require("../components/create/create.personal.user.component.vue");
var edit_user_component_vue_1 = require("../../personal-user/components/edit/edit.user.component.vue");
exports.showPersonalUsers = {
    path: '/personal/users',
    name: 'personalUsers',
    component: show_personal_users_component_vue_1.default,
};
exports.createPersonalUser = {
    path: '/create/personal/user',
    name: 'createPersonalUser',
    component: create_personal_user_component_vue_1.default,
};
exports.editUser = {
    path: '/personal/user/edit/:id',
    name: 'editPersonalUser',
    component: edit_user_component_vue_1.default,
};

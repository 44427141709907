"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.personalUserMutations = void 0;
var personal_user_mutation_types_1 = require("./types/personal.user.mutation.types");
exports.personalUserMutations = (_a = {},
    _a[personal_user_mutation_types_1.PersonalUserMutationTypes.SET_PERSONAL_USERS] = function (state, payload) {
        state.personalUsers = payload.data;
    },
    _a);

window._ = require('lodash');

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    require('bootstrap');
} catch (e) {}

window.axios = require('axios');
window.token = localStorage.getItem('jwt');
if (window.location.href.includes('admin.rescript.we-demonstrate2.ru')) {
    axios.defaults.baseURL = "https://admin.rescript.we-demonstrate2.ru/api/v1";
} else {
    axios.defaults.baseURL = "https://admin.reskript.com/api/v1";
}
axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.token;
axios.defaults.headers.post['Content-Type'] = 'application/json';
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.appMutations = void 0;
var app_mutation_types_1 = require("../types/app.mutation.types");
exports.appMutations = (_a = {},
    _a[app_mutation_types_1.AppMutationTypes.SET_MESSAGE] = function (store, payload) {
        store.error.message = payload;
    },
    _a);

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonalUserActionTypes = void 0;
var PersonalUserActionTypes;
(function (PersonalUserActionTypes) {
    PersonalUserActionTypes["GET_PERSONAL_USERS"] = "GET_PERSONAL_USERS";
    PersonalUserActionTypes["TOGGLE_PERSONAL_USER_BLOCK"] = "TOGGLE_PERSONAL_USER_BLOCK";
    PersonalUserActionTypes["DELETE_PERSONAL_USER"] = "DELETE_PERSONAL_USER";
    PersonalUserActionTypes["UPDATE_USER"] = "UPDATE_USER";
    PersonalUserActionTypes["GET_USER"] = "GET_USER";
    PersonalUserActionTypes["GET_USER_BY_ID"] = "GET_USER_BY_ID";
})(PersonalUserActionTypes = exports.PersonalUserActionTypes || (exports.PersonalUserActionTypes = {}));

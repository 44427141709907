"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.store = void 0;
var vue_1 = require("vue");
var vuex_1 = require("vuex");
var auth_store_1 = require("../modules/auth/store/auth.store");
var app_mutations_1 = require("./store/app.mutations");
var app_getters_1 = require("./store/app.getters");
var personal_user_store_1 = require("../modules/personal-user/store/personal.user.store");
var rescript_user_store_1 = require("../modules/rescript-user/store/rescript.user.store");
var how_to_rescript_store_1 = require("../modules/how-to-rescript/store/how.to.rescript.store");
vue_1.default.use(vuex_1.default);
exports.store = {
    state: {},
    getters: app_getters_1.appGetters,
    mutations: app_mutations_1.appMutations,
    modules: {
        AuthStore: auth_store_1.AuthStore,
        PersonalUserStore: personal_user_store_1.PersonalUserStore,
        RescriptUserStore: rescript_user_store_1.RescriptUserStore,
        HomeInfoStore: how_to_rescript_store_1.HowToRescriptStore
    }
};
exports.default = new vuex_1.default.Store(exports.store);

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.router = void 0;
require("./bootstrap");
var axios_1 = require("axios");
var vue_1 = require("vue");
var app_component_vue_1 = require("./app/app.component.vue");
var app_store_1 = require("./app/app.store");
var app_router_1 = require("./app/app.router");
var vue_router_1 = require("vue-router");
var vuex_1 = require("vuex");
var vue_toasted_1 = require("vue-toasted");
var vue_2 = require("@chakra-ui/vue");
var free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
var CKEditor = require("@ckeditor/ckeditor5-vue2");
var buefy_1 = require("buefy");
require("buefy/dist/buefy.css");
var VueRouterPush = vue_router_1.default.prototype.push;
vue_router_1.default.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(function (err) { return err; });
};
var VueRouterReplace = vue_router_1.default.prototype.replace;
vue_router_1.default.prototype.replace = function replace(to) {
    return VueRouterReplace.call(this, to).catch(function (err) { return err; });
};
vue_1.default.use(vue_router_1.default);
vue_1.default.use(vuex_1.default);
vue_1.default.use(buefy_1.default);
vue_1.default.use(CKEditor, {
    name: 'ckeditor'
});
vue_1.default.use(vue_2.default, {
    icons: {
        iconPack: 'fa',
        iconSet: { 'eye': free_solid_svg_icons_1.faEye, 'eye-slash': free_solid_svg_icons_1.faEyeSlash },
    },
});
vue_1.default.use(vue_toasted_1.default, {
    position: 'bottom-center',
    duration: 2500
});
vue_1.default.use(require('vue-moment'));
vue_1.default.prototype.$http = axios_1.default;
exports.router = new vue_router_1.default({
    routes: app_router_1.default,
    mode: 'history',
});
var app = new vue_1.default({
    el: '#app',
    router: exports.router,
    store: app_store_1.default,
    render: function (h) { return h(vue_2.CThemeProvider, [h(app_component_vue_1.default)]); },
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RescriptUserActionTypes = void 0;
var RescriptUserActionTypes;
(function (RescriptUserActionTypes) {
    RescriptUserActionTypes["GET_RESCRIPT_USERS"] = "GET_RESCRIPT_USERS";
    RescriptUserActionTypes["TOGGLE_RESCRIPT_USER_BLOCK"] = "TOGGLE_RESCRIPT_USER_BLOCK";
    RescriptUserActionTypes["DELETE_RESCRIPT_USER"] = "DELETE_RESCRIPT_USER";
    RescriptUserActionTypes["GET_RESCRIPT_USER"] = "GET_RESCRIPT_USER";
    RescriptUserActionTypes["DOWNLOAD_RESCRIPT_USERS_CSV"] = "DOWNLOAD_RESCRIPT_USERS_CSV";
})(RescriptUserActionTypes = exports.RescriptUserActionTypes || (exports.RescriptUserActionTypes = {}));

"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.rescriptUserMutations = void 0;
var rescript_user_mutations_types_1 = require("./types/rescript.user.mutations.types");
exports.rescriptUserMutations = (_a = {},
    _a[rescript_user_mutations_types_1.RescriptUserMutationsTypes.SET_RESCRIPT_USERS] = function (state, payload) {
        state.rescriptUsers = payload.data;
    },
    _a);

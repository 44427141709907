"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var auth_router_1 = require("../modules/auth/routes/auth.router");
var dashboard_route_1 = require("../modules/dashboard/routes/dashboard.route");
var rescript_user_route_1 = require("../modules/rescript-user/routes/rescript.user.route");
var personal_user_route_1 = require("../modules/personal-user/routes/personal.user.route");
var personal_user_route_2 = require("../modules/personal-user/routes/personal.user.route");
var how_to_rescript_routes_1 = require("../modules/how-to-rescript/routes/how.to.rescript.routes");
dashboard_route_1.dashboard['children'] = [
    personal_user_route_2.createPersonalUser,
    personal_user_route_2.showPersonalUsers,
    rescript_user_route_1.rescriptUser,
    how_to_rescript_routes_1.homeInfo,
    personal_user_route_1.editUser
];
exports.default = [
    auth_router_1.signIn,
    dashboard_route_1.dashboard
];
